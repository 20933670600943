.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  margin: 1em;
  padding: 0.5em 2em 0.5em;
}

.logo {
  align-self: center;
  font-size: 2em;
  font-weight: 900;
}

.burger {
  display: none;
  align-self: center;
}

.burger div {
  height: 3px;
  width: 30px;
  margin: 0.3em;
  border-radius: 20px;
  background-color: var(--primary-light);
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav a {
  padding: 1em;
  text-decoration: none;
  color: var(--primary-light);
  font-weight: 500;
}

.nav .active {
  color: var(--primary-accent);
}

.none {
  display: none;
}

@media (max-width: 500px) {
  .burger {
    display: unset;
    z-index: 500;
  }

  .nav {
    display: none;
  }

  .navmobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    background-color: var(--secondary-dark);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .navmobile a {
    padding: 1em;
    text-decoration: none;
    color: var(--primary-light);
    font-weight: 500;
  }

  .navmobile .active {
    color: var(--primary-accent);
  }
}
