.container {
  margin: 1em;
}

.flex {
  display: flex;
}

.exp::before {
  content: "Experience";
  color: var(--primary-light);
  font-size: 2rem;
  font-weight: 800;
  margin: 0.5em 0 0.3em;
}

.exp,
.edu {
  flex-direction: column;
}

.box {
  flex-direction: row;
  margin: 0.2em 0 0.2em;
  padding: 0.3em;
}

.icon {
  font-size: 1.2rem;
  align-self: center;
  height: 2em;
  width: 2em;
  margin: 0 0 5px;
}

.edu::before {
  content: "Education";
  color: var(--primary-light);
  font-size: 2rem;
  font-weight: 800;
  margin: 0.5em 0 0.3em;
}

.box {
  background-color: var(--secondary-dark);
  padding: 20px;
}

.desc {
  margin: 0 0.8em 0;
}

p:nth-child(1) {
  margin: 0.2em 0 0.2em;
}

p:nth-child(2) {
  font-size: 1.6rem;
  margin: 0.2em 0 0.2em;
}

p:nth-child(3) {
  font-size: 1rem;
  margin: 0.2em 0 0.2em;
}
