@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Mrs+Sheppards&display=swap");

:root {
  --primary-dark: #000000;
  --secondary-dark: #0a0a0a;
  --primary-light: #ffffff;
  --primary-accent: #2196f3;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--primary-dark);
  color: var(--primary-light);
  font-family: "Inter", sans-serif;
}

a {
  text-decoration: none;
  color: var(--primary-light);
}
