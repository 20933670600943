.container {
  display: flex;
  flex-direction: column;
}

.social {
  margin: 1em;
}

.social a {
  font-size: 3rem;
  padding: 0.3em;
}

.social a:hover {
  transform: scale(1.2);
}

form::before {
  content: "Contact Me";
  margin: 0.4em 0 0.4em;
  font-size: 1.2rem;
  padding: 0.1em;
  color: var(--primary-light);
}

form {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-dark);
  padding: 1em;
  border-radius: 10px;
}

form > * {
  padding: 1em;
  margin: 0.1em;
  border-radius: 5px;
  border: none;
}

form > *::placeholder {
  padding: 0.6em;
  border-radius: 0.5em;
  font-family: "Inter", sans-serif;
}

input {
  background-color: var(--primary-light);
}

input:nth-child(5) {
  background-color: var(--primary-accent);
  color: var(--primary-red);
}

textarea {
  padding: 1em;
  margin: 0.1em;
  background-color: var(--primary-light);
}
