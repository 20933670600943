.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  margin: 1em;
  padding: 5em 7em 0.5em;
  height: calc(100vh - 13em);
}

.info {
  align-self: center;
}

h1 {
  font-size: 3rem;
}

h1:nth-child(2) {
  color: var(--primary-accent);
}

p {
  margin: 1em 0 1em 0;
}

.button {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
}

.subbutton {
  background-color: var(--primary-accent);
  padding: 0.5em 1em 0.5em;
  border-radius: 2em;
  margin: 0.4em 0.4em 0 0;
  border: 1px var(--primary-accent) solid;
}

.subbutton2 {
  padding: 0.5em 1em 0.5em;
  border-radius: 2em;
  margin: 0.4em 0.4em 0 0;
  border: 1px var(--primary-accent) solid;
}

img {
  width: 30vw;
  padding: 0.5em;
  margin: 0.3em;
}

@media (max-width: 800px) {
  img {
    display: none;
  }
  .container {
    justify-content: center;
    align-items: center;
    padding: unset;
  }
}
